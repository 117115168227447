import React from 'react'
const BannerHome = ({small, title, button}) => {
    return (
        <section className="flex mt-2 md:mt-20">
                <div className="flex-auto md:flex-1"></div>
                <div className="flex md:flex-1 justify-center md:justify-start">
                    <div className="flex flex-col p-3 md:p-5 rounded-br-xl md:rounded-br-medium  text-white w-9/12 md:w-8/12 xl:w-7/12 md:ml-2 mr-4 text-center">
                        <span className="text-xs md:text-2xl leading-5 md:leading-7 lg:leading-normal font-bold text-primary">
                            { small }
                        </span>
                        <span className="pt-1 text-4xl xl:text-6xl font-bold mt-2 leading-7 md:leading-5 text-primary">
                            { title }
                        </span>
                        <div className="flex">
                            <span className="bg-green p-1 px-3 text-xs md:text-xl group text-white font-bold m-auto mt-2">
                                { button }
                            </span>
                        </div>
                    </div>
                </div>
        </section>
        
    )
}
export default BannerHome;
