
import React from 'react'
import BannerHome from '../components/BannerHome';
import { BannerImages } from '../components/BannerImages';
import Layout from '../layout/Layout'
import Seo from "../layout/Seo"

const Home = ({ pageContext }) => {
    const { locale } = pageContext;

    function Child({locale}){
        return (
            <>
            <Seo 
            title="Inicio" 
            description="Cotizaciones inmediatas de obra civil, construcción, diseño, remodelación, reformas, decoración, acabados, reparaciones localizas y mantenimiento para el sector industrial, comercial y residencial"
            pathname="https:localhost"
        />
         <BannerHome small={locale.home.banner.small} title={locale.home.banner.title} button={locale.home.banner.button} />
        <div className="h-72 md:h-24"></div>
        <section className="pt-0 p-5 md:p-2 font-serif text-secondary">
            <BannerImages locale={locale}></BannerImages>
            <div className="quote-box p-1 mt-5">
                <span className="max-w-2xl m-auto font-light text-primary">
                    <span className="font-normal"><strong>{locale.home.body.div.strongText} </strong></span>{locale.home.body.div.text}
                    <br/><br/>
                    {locale.home.body.phrase}
                </span>
            </div>
        </section>
        </>
        )
    }
    return (
        <Layout locale={locale} background="bg-image-home bg-top-2">
            {props => <Child {...props} />}
        </Layout>
    )
}

export default Home
